define("discourse/plugins/discourse-group-membership-ip-block/discourse/templates/connectors/group-edit/field-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group ip-blocks-list">
    <label class="control-label" for="ip_blocks_list">
      {{i18n "admin.groups.ip_blocks_list.label"}}
    </label>
    <ValueList
      @values={{this.group.custom_fields.ip_blocks_list}}
      @name="ip_blocks_list"
      class="input-xxlarge"
    />
    <div class="desc">{{i18n "admin.groups.ip_blocks_list.description"}}</div>
  </div>
  */
  {
    "id": "gKR+73Sp",
    "block": "[[[10,0],[14,0,\"control-group ip-blocks-list\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[14,\"for\",\"ip_blocks_list\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"admin.groups.ip_blocks_list.label\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[24,0,\"input-xxlarge\"]],[[\"@values\",\"@name\"],[[30,0,[\"group\",\"custom_fields\",\"ip_blocks_list\"]],\"ip_blocks_list\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"desc\"],[12],[1,[28,[35,0],[\"admin.groups.ip_blocks_list.description\"],null]],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"value-list\"]]",
    "moduleName": "discourse/plugins/discourse-group-membership-ip-block/discourse/templates/connectors/group-edit/field-container.hbs",
    "isStrictMode": false
  });
});